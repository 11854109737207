var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "tit-wrap text-center"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("아이디 찾기")]), _vm._m(0), _c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-radio-group', {
    staticClass: "mt-16px mt-lg-20px",
    attrs: {
      "row": "",
      "hide-details": "auto"
    },
    model: {
      value: _vm.type,
      callback: function ($$v) {
        _vm.type = $$v;
      },
      expression: "type"
    }
  }, [_c('v-radio', _vm._b({
    attrs: {
      "label": "개인회원",
      "value": _vm.USER_TYPES.PERSON.value
    }
  }, 'v-radio', Object.assign({}, _vm.radio_primary), false))], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mx-auto",
    attrs: {
      "tile": "",
      "max-width": "416"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "block": "",
      "x-large": ""
    },
    on: {
      "click": _vm.certify
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary), false), [_vm._v("휴대폰 인증")])], 1), _c('v-divider', {
    staticClass: "my-20px my-lg-40px"
  }), _c('div', {
    staticClass: "tit-wrap text-center"
  }, [_c('h2', {
    staticClass: "tit tit--xxs"
  }, [_vm._v("등록된 정보로 아이디 찾기")]), _vm._m(1), _c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-radio-group', {
    staticClass: "mt-16px mt-lg-20px",
    attrs: {
      "row": "",
      "hide-details": "auto"
    },
    model: {
      value: _vm.form.type,
      callback: function ($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, [_c('v-radio', _vm._b({
    attrs: {
      "label": "개인회원",
      "value": _vm.USER_TYPES.PERSON.value
    }
  }, 'v-radio', Object.assign({}, _vm.radio_primary), false)), _c('v-radio', _vm._b({
    attrs: {
      "label": "입점사회원",
      "value": _vm.USER_TYPES.COMPANY.value
    }
  }, 'v-radio', Object.assign({}, _vm.radio_primary), false))], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mx-auto",
    attrs: {
      "tile": "",
      "max-width": "416"
    }
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "d-none d-lg-block font-weight-bold",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("이름")]), _c('v-col', [_c('v-text-field', _vm._b({
    attrs: {
      "dense": "",
      "placeholder": "이름을 입력해주세요."
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_secondary), false))], 1)], 1), _c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "d-none d-lg-block font-weight-bold",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("이메일")]), _c('v-col', [_c('v-text-field', _vm._b({
    attrs: {
      "dense": "",
      "type": "email",
      "placeholder": "이메일을 입력해주세요."
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_secondary), false))], 1)], 1), _c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "d-none d-lg-block font-weight-bold",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("보안문자")]), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "9"
    }
  }, [_c('re-captcha', {
    ref: "reCaptcha",
    on: {
      "input": _vm.recaptcha
    }
  })], 1)], 1), _c('div', {
    staticClass: "btn-wrap btn-wrap--md"
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "block": "",
      "x-large": ""
    },
    on: {
      "click": _vm.find
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary), false), [_vm._v("확인")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "block": "",
      "x-large": "",
      "to": "/join"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("회원가입")])], 1)], 1)], 1)], 1), _c('find-id-dialog', {
    attrs: {
      "showDialog": _vm.showDialog
    },
    on: {
      "dialog": function (dialog) {
        return _vm.showDialog = dialog;
      }
    }
  }), _c('kcp-cert', {
    ref: "kcpCert",
    on: {
      "input": _vm.verify
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v(" 휴대폰 인증을 해주세요."), _c('br'), _vm._v(" 인증에 성공하면 아이디를 확인할 수 있습니다. ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("회원가입 시 등록한 정보로 "), _c('br', {
    staticClass: "d-lg-none"
  }), _vm._v("아이디를 찾을 수 있습니다.")]);

}]

export { render, staticRenderFns }